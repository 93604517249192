import React from "react";
import PricingImage from '../assets/HF2.jpg';
import GelImage from '../assets/NE.jpg';
import NailArtImage from '../assets/Nailat.jpg';
import MakeupLA from '../assets/ML2.webp';

const pricingData = [
    { title: 'Manucure y compris masque pour les mains', price: '80 DH' },
    { title: 'Manucure y compris vernis permanent', price: '150 DH' },
    { title: 'Pédicure y compris traitement pour les pieds', price: '70 DH' },
    { title: 'Pédicure y compris vernis permanent', price: '110 DH' },
    { title: 'Depose vernis permanent ', price: '50 DH' },
];

const nailExtensionsData = [
    { title: 'Dépose de gel', price: '100 DH' },
    { title: 'Remplissage Gel ', price: '100 DH' },
    { title: 'Faux ongles permanent', price: '160 DH' },
    { title: 'Gel Babyboomer', price: '250 DH' },
    { title: 'Gel + Permanent ', price: '280 DH' },
    { title: 'Gel-X ', price: '300 DH' },
    { title: 'Gel Biab ', price: '350 DH' },
];

const nailArtData = [
    { title: 'Diamants et strass à partir de', price: '10 DH par nail' },
    { title: 'Autocollants et feuilles pour ongles', price: '10 DH par nail' },
    { title: 'Ongles mattes', price: '40 DH' },
    { title: 'French permanent ', price: '120 DH' },
    { title: 'Permanent sur Ongles naturels ', price: '100 DH' },
];

const maKeup = [
    { title: 'Makeup du jour', price: '150 DH' },
    { title: 'Makeup Coctail', price: '250 DH' },
    { title: 'Makeup Soiree/mariee ', price: ' A partir de 500 DH' },
    { title: 'Extension de cils ', price: ' 100 DH' },
    { title: 'Extension de cils permanents ', price: ' A partir de 200 DH' },
];

function PriceList({ items }) {
    return (
        <ul className="space-y-2">
            {items.map((item, index) => (
                <li key={index} className="flex justify-between items-center">
                    <span className="text-darkBeige">{item.title}</span>
                    <span className="font-semibold text-saddleBrown">{item.price}</span>
                </li>
            ))}
        </ul>
    );
}

function PricingSection({ title, image, data, id, reference }) {
    return (
        <div id={id} ref={reference} className="mb-16">
            <h3 className="text-2xl font-bold text-darkSienna mb-6">{title}</h3>
            <div className="flex flex-col md:flex-row gap-8">
                <div className="md:w-1/2">
                    <img src={image} alt={title} className="w-full h-64 object-cover rounded-lg shadow-md" />
                </div>
                <div className="md:w-1/2">
                    <PriceList items={data} />
                    <a href="https://wa.me/212721030872" target="_blank" rel="noopener noreferrer" className="mt-6 inline-block">
                        <button className="bg-gold text-beige px-6 py-2 rounded-full hover:bg-darkGold transition duration-300">
                            BOOK NOW
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

function Pricing({ pricingRef, nailExtensionsRef, nailArtRef, makeupRef }) {
    return (
        <div className="bg-beige-80 py-24 mt-20"> {/* Added mt-20 for space below navbar */}
            <div className="container mx-auto px-4 max-w-5xl">
                <h2 className="text-4xl font-bold text-center text-darkBeige mb-12">Nos Catalogues & Prix</h2>

                <PricingSection
                    title="HAND- AND FEETCARE"
                    image={PricingImage}
                    data={pricingData}
                    id="handAndFeetCare"
                    reference={pricingRef}
                />

                <PricingSection
                    title="NAIL EXTENSIONS"
                    image={GelImage}
                    data={nailExtensionsData}
                    id="nailExtensions"
                    reference={nailExtensionsRef}
                />

                <PricingSection
                    title="NAIL ART"
                    image={NailArtImage}
                    data={nailArtData}
                    id="nailArt"
                    reference={nailArtRef}
                />

                <PricingSection
                    title="MAKEUP AND LASHES"
                    image={MakeupLA}
                    data={maKeup}
                    id="makeupAndLashes"
                    reference={makeupRef}
                />
            </div>
        </div>
    );
}

export default Pricing;

