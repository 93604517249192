import React, { useState, useEffect } from "react";
import Logo from "../assets/Logo2.png";
import { Link } from "react-router-dom";
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function Navbar() {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [prevScrollPos]);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const drawerContent = (
        <div className="w-64 h-full bg-opacity-80 bg-white">
            <List>
                <ListItem button onClick={toggleDrawer}>
                    <Link to="/" className="text-gold text-lg hover:text-darkGold">
                        <ListItemText primary="Home" />
                    </Link>
                </ListItem>
                <ListItem button onClick={toggleDrawer}>
                    <Link to="/catalogue" className="text-gold text-lg hover:text-darkGold">
                        <ListItemText primary="Catalogue" />
                    </Link>
                </ListItem>
                <ListItem button onClick={toggleDrawer}>
                    <Link to="/about" className="text-gold text-lg hover:text-darkGold">
                        <ListItemText primary="About" />
                    </Link>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className={`w-full bg-beige fixed top-0 z-50 shadow-md border-t-2 border-b-2 border-gold transition-all duration-300 ${visible ? '' : '-translate-y-full'}`}>
            <div className="w-11/12 max-w-6xl h-24 flex justify-between items-center relative mx-auto">
                <div className="flex items-center">
                    <img src={Logo} alt="Logo" className="w-16 mr-2"/>
                    <span className="text-gold text-2xl font-bold">Jasmine Esthetique</span>
                </div>
                <div className="hidden md:flex items-center space-x-4">
                    <Link to="/" className="text-gold text-lg hover:text-darkGold">Home</Link>
                    <Link to="/catalogue" className="text-gold text-lg hover:text-darkGold">Catalogue</Link>
                    <Link to="/about" className="text-gold text-lg hover:text-darkGold">About</Link>
                </div>
                <div className="block md:hidden">
                    <IconButton className="text-gold text-2xl  ml-4" onClick={toggleDrawer}>
                        <MenuIcon className="w-8 h-8"/>
                    </IconButton>
                </div>
            </div>
            <Drawer
                anchor="right"
                open={openDrawer}
                onClose={toggleDrawer}
                classes={{paper: 'bg-transparent'}}
            >
                {drawerContent}
            </Drawer>
        </div>
    );
}

export default Navbar;


