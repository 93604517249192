import React from 'react';
const Timeline = ({ items }) => {
    return (
        <div className="max-w-4xl mx-auto p-5 bg-beige bg-opacity-80 border-3 border-saddleBrown rounded-lg relative">
            <div className="absolute top-0 bottom-0 left-1/2 w-1.5 bg-saddleBrown hidden md:block"></div>
            {items.map((item, index) => (
                <div key={index} className="flex flex-col md:flex-row mb-8 md:mb-12">
                    <div className={`w-full md:w-1/2 ${index % 2 === 0 ? 'md:pr-8' : 'md:pl-8 md:order-last'}`}>
                        <img src={item.image} alt={item.title} className="w-full h-auto rounded-lg shadow-lg mb-4 md:mb-0" />
                    </div>
                    <div className={`w-full md:w-1/2 ${index % 2 === 0 ? 'md:pl-8' : 'md:pr-8'}`}>
                        <div className="bg-beige bg-opacity-90 p-6 rounded-lg border-3 border-saddleBrown shadow-lg">
                            <h3 className="text-xl font-bold text-saddleBrown mb-2">{item.title}</h3>
                            <p className="text-darkBeige">{item.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;







