import React from 'react';
import '../styles/Pricing.css';
import PricingImage from '../assets/HF2.jpg';
import GelImage from '../assets/NE.jpg';
import NailArtImage from '../assets/Nailat.jpg';
import MakeupLA from '../assets/ML2.webp';

const pricingData = [
  { title: 'Manucure y compris masque pour les mains', price: '80 DH' },
  { title: 'Manucure y compris vernis permanent', price: '150 DH' },
  { title: 'Pédicure y compris traitement pour les pieds', price: '70 DH' },
  { title: 'Pédicure y compris vernis permanent', price: '110 DH' },
  { title: 'Depose vernis permanent ', price: '50 DH' },
];

const nailExtensionsData = [
  { title: 'Dépose de gel', price: '100 DH' },
  { title: 'Remplissage Gel ', price: '100 DH' },
  { title: 'Faux ongles permanent', price: '160 DH' },
  { title: 'Gel Babyboomer', price: '250 DH' },
  { title: 'Gel + Permanent ', price: '280 DH' },
  { title: 'Gel-X ', price: '300 DH' },
  { title: 'Gel Biab ', price: '350 DH' },
];

const nailArtData = [
  { title: 'Diamants et strass à partir de', price: '10 DH par nail' },
  { title: 'Autocollants et feuilles pour ongles', price: '10 DH par nail' },
  { title: 'Ongles mattes', price: '40 DH' },
  { title: 'French permanent ', price: '120 DH' },
  { title: 'Permanent sur Ongles naturels ', price: '100 DH' },
];

const maKeup = [
  { title: 'Makeup du jour', price: '150 DH' },
  { title: 'Makeup Coctail', price: '250 DH' },
  { title: 'Makeup Soiree/mariee ', price: ' A partir de 500 DH' },
  { title: 'Extension de cils ', price: ' 100 DH' },
  { title: 'Extension de cils permanents ', price: ' A partir de 200 DH' },
];

function Pricing({ pricingRef, nailExtensionsRef, nailArtRef, makeupRef }) {
  return (
    <div className="pricingWrapper">
      <div className="pricingContainer">
        <h2 className="mainTitle">Nos Catalogues & Prix</h2>

        <div id="handAndFeetCare" className="sectionTitle" ref={pricingRef}>
          HAND- AND FEETCARE
        </div>
        <div className="pricingContent sectionSpacing">
          <div className="pricingImageContainer">
            <img src={PricingImage} alt="Traitements" className="pricingImage" />
          </div>
          <div className="pricingDetails">
            <ul className="priceList">
              {pricingData.map((item, index) => (
                <li key={index} className="priceItem">
                  <span className="serviceTitle">{item.title}</span>
                  <span className="servicePrice">{item.price}</span>
                </li>
              ))}
            </ul>
            <a href="https://wa.me/212721030872" target="_blank" rel="noopener noreferrer">
              <button className="bookButton">BOOK NOW</button>
            </a>
          </div>
        </div>

        <div id="nailExtensions" className="sectionTitle" ref={nailExtensionsRef}>
          NAIL EXTENSIONS
        </div>
        <div className="pricingContent sectionSpacing">
          <div className="pricingImageContainer">
            <img src={GelImage} alt="Extensions d'ongles" className="pricingImage" />
          </div>
          <div className="pricingDetails">
            <ul className="priceList">
              {nailExtensionsData.map((item, index) => (
                <li key={index} className="priceItem">
                  <span className="serviceTitle">{item.title}</span>
                  <span className="servicePrice">{item.price}</span>
                </li>
              ))}
            </ul>
            <a href="https://wa.me/212721030872" target="_blank" rel="noopener noreferrer">
              <button className="bookButton">BOOK NOW</button>
            </a>
          </div>
        </div>

        <div id="nailArt" className="sectionTitle" ref={nailArtRef}>
          NAIL ART
        </div>
        <div className="pricingContent sectionSpacing">
          <div className="pricingImageContainer">
            <img src={NailArtImage} alt="Nail Art" className="pricingImage" />
          </div>
          <div className="pricingDetails">
            <ul className="priceList">
              {nailArtData.map((item, index) => (
                <li key={index} className="priceItem">
                  <span className="serviceTitle">{item.title}</span>
                  <span className="servicePrice">{item.price}</span>
                </li>
              ))}
            </ul>
            <a href="https://wa.me/212721030872" target="_blank" rel="noopener noreferrer">
              <button className="bookButton">BOOK NOW</button>
            </a>
          </div>
        </div>

        <div id="makeupAndLashes" className="sectionTitle" ref={makeupRef}>
          MAKEUP AND LASHES
        </div>
        <div className="pricingContent sectionSpacing">
          <div className="pricingImageContainer">
            <img src={MakeupLA} alt="Extensions d'ongles" className="pricingImage" />
          </div>
          <div className="pricingDetails">
            <ul className="priceList">
              {maKeup.map((item, index) => (
                <li key={index} className="priceItem">
                  <span className="serviceTitle">{item.title}</span>
                  <span className="servicePrice">{item.price}</span>
                </li>
              ))}
            </ul>
            <a href="https://wa.me/212721030872" target="_blank" rel="noopener noreferrer">
              <button className="bookButton">BOOK NOW</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
