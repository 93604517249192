import React from "react";
import AboutImage1 from "../assets/3 (1).jpg";
import AboutImage2 from "../assets/3 (2).jpg";
import { FaInstagram, FaFacebookF, FaTiktok } from "react-icons/fa";

function About() {
  return (
      <div className="bg-gray-100 py-20 px-4 sm:px-6 lg:px-8 min-h-screen flex items-center">
        <div className="max-w-6xl mx-auto w-full">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            <div className="md:flex">
              <div className="md:flex-1 p-8 lg:p-12">
                <h1 className="text-3xl sm:text-4xl font-bold text-yellow-600 mb-6">À PROPOS DE NOUS</h1>
                <div className="space-y-4 text-gray-600">
                  <p>
                    Bienvenue chez <span className="text-yellow-600 font-semibold">Jasmine Esthetique</span>, votre destination ultime pour une beauté des ongles exceptionnelle. Fondée avec passion et dévouement, notre mission est de transformer chaque ongle en une œuvre d'art.
                  </p>
                  <p>
                    <span className="text-yellow-600 font-semibold">Jasmine Esthetique</span> est dirigée par Jasmine, une spécialiste diplômée qui réalise son travail avec amour et perfection. Elle propose des services à domicile ou se déplace chez vous avec son matériel professionnel moyennant un <span className="text-yellow-600 font-semibold">supplément de 40 DH</span>.
                  </p>
                  <p>
                    Chez <span className="text-yellow-600 font-semibold">Jasmine Esthetique</span>, nous sommes plus qu'un salon de manucure ; nous sommes une communauté qui célèbre l'individualité et l'expression personnelle à travers le design des ongles. Nos artistes talentueux combinent expertise technique et créativité pour créer des styles uniques qui captivent et inspirent.
                  </p>
                  <p>
                    Nous croyons en l'importance de la qualité, de l'hygiène et du service client exceptionnel. Chaque visite chez <span className="text-yellow-600 font-semibold">Jasmine Esthetique</span> est une expérience personnalisée, où vous êtes accueilli chaleureusement et traité avec soin.
                  </p>
                  <p>
                    Rejoignez-nous chez <span className="text-yellow-600 font-semibold">Jasmine Esthetique</span> et laissez-nous vous aider à révéler votre style unique, un ongle à la fois.
                  </p>
                </div>
                <div className="mt-8 space-y-4">
                  <a href="https://wa.me/212721030872"
                  className="block w-full sm:w-auto text-center bg-yellow-600 hover:bg-yellow-700 text-white font-bold py-3 px-6 rounded-lg transition duration-300"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  Contactez-nous via WhatsApp
                </a>
                <div className="flex justify-center space-x-6">
                  <a href="https://www.instagram.com/jasminexnails/" className="text-yellow-600 hover:text-yellow-700 transition duration-300" target="_blank" rel="noopener noreferrer"><FaInstagram size={24} /></a>
                  <a href="https://www.facebook.com/thewinxglam" className="text-yellow-600 hover:text-yellow-700 transition duration-300" target="_blank" rel="noopener noreferrer"><FaFacebookF size={24} /></a>
                  <a href="https://www.tiktok.com/@jasminexnail?_t=8nFtXIKRFvJ&_r=1" className="text-yellow-600 hover:text-yellow-700 transition duration-300" target="_blank" rel="noopener noreferrer"><FaTiktok size={24} /></a>
                </div>
              </div>
            </div>
            <div className="md:w-1/3 p-8 bg-gray-50">
              <div className="space-y-4">
                <img src={AboutImage1} alt="About Us" className="w-full h-auto rounded-lg shadow-md" />
                <img src={AboutImage2} alt="About Us" className="w-full h-auto rounded-lg shadow-md" />
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
);
}

export default About;