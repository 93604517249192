import React from 'react';
import { FaInstagram, FaFacebook, FaTiktok } from 'react-icons/fa';

function Footer() {
  return (
      <footer className="bg-beige-80 text-darkBeige py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-start">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-darkSienna">VOUS SOUHAITEZ PRENDRE RENDEZ-VOUS ?</h2>
              <p>Téléphone: <a href="tel:+212721030872" className="hover:text-gold transition-colors">+212 721 030 872</a></p>
              <p>Email: <a href="mailto:Jasmine.mh@outlook.com" className="hover:text-gold transition-colors">Jasmine.mh@outlook.com</a></p>
            </div>

            <div className="w-full h-64 md:h-full">
              <div className="relative w-full h-full min-h-[250px]">
                <iframe
                    src="https://maps.google.com/maps?q=marrakech%20targa&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    className="absolute top-0 left-0 w-full h-full"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    title="Google Map"
                ></iframe>
              </div>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-saddleBrown">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p>&copy; 2024, jasminesthetique.com , Marrakech</p>
              <div className="mt-4 md:mt-0 space-y-4 md:space-y-0">
                <h2 className="text-xl font-bold text-darkSienna text-center md:text-right">Suivez-nous :</h2>
                <div className="flex space-x-4">
                  <a href="https://www.instagram.com/jasminexnails/" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-gold transition-colors">
                    <FaInstagram />
                  </a>
                  <a href="https://www.facebook.com/thewinxglam" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-gold transition-colors">
                    <FaFacebook />
                  </a>
                  <a href="https://www.tiktok.com/@jasminexnail?_t=8nFtXIKRFvJ&_r=1" target="_blank" rel="noopener noreferrer" className="text-2xl hover:text-gold transition-colors">
                    <FaTiktok />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
}

export default Footer;




