import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { FaTiktok } from "react-icons/fa";
import Timeline from '../components/Timelines';
import Pricing from '../components/Pricing';
import AdComponent from '../components/AdComponent'; // Importer le composant AdComponent
import Maquillage from '../assets/makeup.jpg';
import Gel from '../assets/gel.jpg';
import Permanant from '../assets/permanent.jpg';
import Manucure from '../assets/manucure1.jpg';
import FauxCile from '../assets/fs.jpg';
import bgImage from '../assets/1.jpg';

const services = [
    {
        title: 'Maquillage',
        description: 'Des services de maquillage professionnels pour toutes vos occasions spéciales.',
        image: Maquillage,
    },
    {
        title: 'Gel',
        description: 'Nous offrons des services de pose de gel de haute qualité pour des ongles forts et brillants.',
        image: Gel,
    },
    {
        title: 'Permanent',
        description: 'Nos vernis permanents garantissent une tenue impeccable pendant plusieurs semaines.',
        image: Permanant,
    },
    {
        title: 'Manucure',
        description: 'Profitez de nos manucures soignées et relaxantes pour des ongles impeccables.',
        image: Manucure,
    },
    {
        title: 'Faux cils',
        description: 'Des faux cils de haute qualité pour un regard intensifié.',
        image: FauxCile,
    },
];

function Home() {
    const pricingRef = useRef(null);
    const nailExtensionsRef = useRef(null);
    const nailArtRef = useRef(null);
    const makeupRef = useRef(null);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 200) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="w-full mt-16">
            <div className="w-full min-h-screen flex flex-col items-center bg-beige font-sans text-center">
                <div
                    className="w-full h-96 bg-cover bg-center flex justify-center items-center"
                    style={{ backgroundImage: `url(${bgImage})` }}
                >
                    <div className="bg-beige bg-opacity-90 border-3 border-saddleBrown rounded-md p-5 text-darkBeige">
                        <h1 className="text-6xl mb-2">Jasmine Esthetique</h1>
                        <h3 className="text-lg mb-1 cursor-pointer hover:text-black" onClick={() => scrollToSection(pricingRef)}>HAND- AND FEETCARE</h3>
                        <h3 className="text-lg mb-1 cursor-pointer hover:text-black" onClick={() => scrollToSection(nailExtensionsRef)}>NAIL EXTENSIONS</h3>
                        <h3 className="text-lg mb-1 cursor-pointer hover:text-black" onClick={() => scrollToSection(nailArtRef)}>NAIL ART</h3>
                        <h3 className="text-lg mb-1 cursor-pointer hover:text-black" onClick={() => scrollToSection(makeupRef)}>MAKEUP AND LASHES</h3>
                        <Link to="/catalogue">
                            <button className="mt-3 px-5 py-2 text-lg text-white bg-sienna border-none rounded-md cursor-pointer hover:bg-darkSienna">Notre Catalogue</button>
                        </Link>
                    </div>
                </div>
                <div className="w-full py-5 bg-lightBeige flex flex-col items-center">
                    <div className="flex space-x-3">
                        <a href="https://www.instagram.com/jasminexnails/" target="_blank" rel="noopener noreferrer"
                           className="text-sienna hover:text-darkSienna">
                            <InstagramIcon className="w-8 h-8"/>
                        </a>
                        <a href="https://www.facebook.com/thewinxglam" target="_blank" rel="noopener noreferrer"
                           className="text-sienna hover:text-darkSienna">
                            <FacebookIcon className="w-8 h-8"/>
                        </a>
                        <a href="https://www.tiktok.com/@jasminexnail?_t=8nFtXIKRFvJ&_r=1" target="_blank"
                           rel="noopener noreferrer" className="socialLink text-sienna hover:text-darkSienna">
                            <FaTiktok className="w-6 h-6"/>
                        </a>
                    </div>
                    <div className="text-darkBeige mt-3 text-center">
                        <p className="mb-3">
                            Jasmine Esthetique -> {' '}
                            <a href="mailto:Jasmine.mh@outlook.com"
                               className="text-saddleBrown hover:text-darkSienna">Jasmine.mh@outlook.com </a>{' '}
                        </p>
                        <p>
                            <a href="tel:+212721030872" className="text-saddleBrown hover:text-darkSienna"><span
                                className="text-black">To Call Us -></span> +212 721 030
                                872</a>
                        </p>
                        <p className="mt-3">
                            <a href="tel:+212721030872" className="text-saddleBrown hover:text-darkSienna"><span
                                className="text-black">Localisation -></span> Marrakech</a>
                        </p>
                    </div>
                    <div
                        className="flex flex-col md:flex-row justify-center md:justify-start space-y-3 md:space-y-0 md:space-x-5 mt-3">
                        <a href="https://wa.me/212721030872" target="_blank" rel="noopener noreferrer">
                        <button
                                className="px-6 py-3 text-lg text-white bg-sienna border-none rounded-md cursor-pointer hover:bg-darkSienna">WhatsApp
                            </button>
                        </a>
                        <a href="tel:+212721030872">
                            <button
                                className="px-6 py-3 text-lg text-white bg-sienna border-none rounded-md cursor-pointer hover:bg-darkSienna">Appeler
                                Maintenant
                            </button>
                        </a>
                    </div>
                </div>
                <div className="w-full py-10 bg-white flex flex-col items-center">
                    <h2 className="text-saddleBrown mb-5">Nos Services</h2>
                    <Timeline items={services}/>
                </div>
                <Pricing
                    pricingRef={pricingRef}
                    nailExtensionsRef={nailExtensionsRef}
                    nailArtRef={nailArtRef}
                    makeupRef={makeupRef}
                />
                <AdComponent /> {/* Ajouter l'AdComponent ici */}
                {showScrollButton && (
                    <button
                        className="fixed bottom-5 right-5 px-4 py-2 text-sm bg-sienna text-white rounded-md cursor-pointer hover:opacity-80 z-50"
                        onClick={scrollToTop}>
                        Retourner en Haut
                    </button>
                )}
            </div>
        </div>
    );
}

export default Home;
