import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Corrected import name to Footer
import Home from './pages/Home';
import Catalogue from './pages/Catalogue';
import About from './pages/About';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes> {/* Use Routes instead of Switch */}
          <Route path='/' element={<Home />} />
          <Route path='/catalogue' element={<Catalogue />} />
          <Route path='/about' element={<About /> } /> 
*
        </Routes>
        <Footer /> {/* Corrected component name to Footer */}
      </Router>
    </div>
  );
}

export default App;
